
import Vue, { type PropType } from "vue"
import type {
  ChatMessage,
  ChatMessageAnnotation,
  ChatSuggestion,
  EMarkdownRegex,
} from "@evercam/ui"
import { ChatMessageRole, ChatMessageType } from "@evercam/ui"
import {
  AnalyticsEvent,
  type Camera,
  type CameraExid,
  CameraFeatureFlag,
  type CamerasByExid,
  CopilotChatProvider,
  CopilotSocketEvent,
  CopilotSuggestion,
  type CopilotTimelapse,
  FeedbackContext,
  type FeedbackPayload,
  type Project,
  type ProjectExid,
  ProjectFeatureFlag,
  type ProjectsByExid,
  type User,
} from "@evercam/shared/types"
import { io, Socket } from "socket.io-client"
import axios from "@evercam/shared/api/client/axios"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import CopilotAnnotation from "@evercam/shared/components/copilot/CopilotAnnotation"
import CopilotAnprThumbnail from "@evercam/shared/components/copilot/CopilotAnprThumbnail"
import BetaTag from "@evercam/shared/components/BetaTag"
import { shuffleArray } from "@evercam/shared/utils"

export default Vue.extend({
  name: "CopilotChat",
  components: { BetaTag, CopilotAnprThumbnail, CopilotAnnotation },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    token: {
      type: String,
      default: "",
    },
    selectedCamera: {
      type: [Object, undefined] as PropType<Camera | undefined>,
      default: undefined,
    },
    selectedProject: {
      type: [Object, undefined] as PropType<Project | undefined>,
      default: undefined,
    },
    selectedTimelapse: {
      type: [Object, undefined] as PropType<CopilotTimelapse | undefined>,
      default: undefined,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    embedded: {
      type: Boolean,
      default: true,
    },
    provider: {
      type: String as PropType<CopilotChatProvider>,
      default: () => CopilotChatProvider.ChatGpt,
    },
    timelapse: {
      type: [Object, undefined] as PropType<CopilotTimelapse | undefined>,
      default: undefined,
    },
    eMarkdownProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      socket: null as unknown as Socket,
      activeIndex: 0,
      messages: [] as ChatMessage[],
      userMessage: "",
      isShiftKeyPressed: false,
      queuedChunk: "",
      loadingMessage: "" as string | string[],
      remoteMessageIds: {} as Record<number, number>,
      conversationId: 0,
      isAwaitingMessage: false,
      isReceivingMessage: false,
      annotations: {} as Record<string, { url: string; index: number }>,
      isInitialized: false,
    }
  },
  computed: {
    lastMessage(): ChatMessage {
      return this.messages.slice(-1)[0]
    },
    isCopilotTurn(): boolean {
      return this.lastMessage.role === ChatMessageRole.COPILOT
    },
    hasGateReport(): boolean {
      return this.selectedProject!.featureFlags.includes(
        ProjectFeatureFlag.GATE_REPORT
      )
    },
    isReadonly(): boolean {
      return (
        this.provider === CopilotChatProvider.GeminiTimelapse && !this.timelapse
      )
    },
    hasTimelapseReports(): boolean {
      return !!this.selectedProject?.cameras?.some((c) =>
        c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
      )
    },
    projectExidRegex(): RegExp {
      const exids = Object.keys(this.projectsByExid)

      return new RegExp(`(?<!/)(${exids.join("|")})(?!/)`, "gmi")
    },
    cameraExidRegex(): RegExp {
      const exids = Object.keys(this.camerasByExid)

      return new RegExp(`(?<!/)(${exids.join("|")})(?!/)`, "gmi")
    },
    exposedMarkdownRegexes(): EMarkdownRegex[] {
      let regexes = [
        {
          type: "timestamp",
          regex:
            /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2}))|(\$\$(?:.*?)\$\$)/gm,
        },
        {
          type: "cameraExid",
          regex: this.cameraExidRegex,
        },
        {
          type: "projectExid",
          regex: this.projectExidRegex,
        },
        {
          type: "annotation",
          regex: /(【.*†source】)/gm,
        },
        {
          type: "anprThumbnail",
          regex: /(grt_.*_grt)/gm,
        },
      ]

      if (this.timelapse) {
        regexes.push({
          type: "videoTimestamp",
          regex: /[^a-zA-Z0-9]?(\d{1,2}:\d{2})[^a-zA-Z0-9]?/gm,
        })
      }

      return regexes
    },
    suggestions(): ChatSuggestion[] {
      if (this.provider === CopilotChatProvider.GeminiTimelapse) {
        return []
      }

      const suggestionWidth = 165
      const getRandomIndex = () => Math.floor(Math.random() * 5)
      let suggestions = [
        {
          type: CopilotSuggestion.Weather,
          text: this.$t(`copilot.suggestions.weather_${getRandomIndex()}`),
          icon: "fas fa-cloud-sun",
          width: suggestionWidth,
          color: this.suggestionsCardIconColor,
        },
      ]

      if (this.hasGateReport) {
        suggestions.push({
          type: CopilotSuggestion.GateReport,
          text: this.$t(`copilot.suggestions.gate_report_${getRandomIndex()}`),
          icon: "fas fa-truck",
          width: suggestionWidth,
          color: this.suggestionsCardIconColor,
        })
      }

      if (this.hasTimelapseReports) {
        suggestions.push({
          type: CopilotSuggestion.SiteActivity,
          text: this.$t(
            `copilot.suggestions.site_activity_${getRandomIndex()}`
          ),
          icon: "fas fa-chart-line",
          width: suggestionWidth,
        })
      }

      for (let i = 0; i <= 3 - suggestions.length; i++) {
        suggestions.push({
          type: CopilotSuggestion.UserManual,
          text: this.$t(`copilot.suggestions.user_manual_${getRandomIndex()}`),
          icon: "far fa-circle-question",
          width: suggestionWidth,
          color: this.suggestionsCardIconColor,
        })
      }

      return shuffleArray(suggestions)
    },
    suggestionsCardIconColor(): string {
      return this.$vuetify.theme.dark ? "#64748b" : "#94a3b8"
    },
  },
  watch: {
    timelapse() {
      if (this.conversationId) {
        this.startNewConversation()
      }
    },
  },
  mounted() {
    this.initSocket()
    this.focusInput()
  },
  methods: {
    initSocket() {
      this.socket = io(axios.env.evercamLabsUrl as string, {
        path: "/ws/copilot",
        transports: ["websocket"],
        query: {
          userEmail: this.user.email,
        },
        auth: {
          token: this.token,
        },
      })

      this.socket
        .on(CopilotSocketEvent.LLMMessageChunk, this.onLLMMessageChunk)
        .on(CopilotSocketEvent.LLMMessageComplete, this.onLLMMessageComplete)
        .on(CopilotSocketEvent.ChatError, this.onChatError)
        .on(CopilotSocketEvent.LLMRequestCanceled, this.onRequestCanceled)
    },
    async startConversation() {
      return new Promise((resolve) => {
        this.socket
          .emit(
            CopilotSocketEvent.ConversationStart,
            {
              projectExid: this.selectedProject?.exid,
              cameraExid: this.selectedCamera?.exid,
              timelapseId: this.selectedTimelapse?.id,
              timelapse: this.timelapse,
            },
            this.provider
          )
          .on(
            CopilotSocketEvent.ConversationCreated,
            (conversationId: number) => {
              this.conversationId = conversationId
              resolve()
            }
          )
      })
    },
    async startNewConversation() {
      this.isInitialized = false
      this.conversationId = 0
      this.socket.disconnect()
      this.socket.connect()
      await this.startConversation()
    },
    async onUserMessage() {
      if (!this.isInitialized) {
        await this.startConversation()
        this.isInitialized = true
      }
      if (this.isShiftKeyPressed) {
        return
      }
      if (!this.userMessage.trim()) {
        return
      }
      if (
        this.messages[this.messages.length - 1]?.type === ChatMessageType.ERROR
      ) {
        this.messages.pop()
      }
      this.$analytics.saveEvent(AnalyticsEvent.copilotSendMessage)
      this.socket.emit(CopilotSocketEvent.UserMessage, this.userMessage)
      this.isAwaitingMessage = true
      this.messages.push({
        id: Date.now(),
        content: this.userMessage,
        role: ChatMessageRole.USER,
        timestamp: new Date().toISOString(),
      })
      this.userMessage = ""
    },
    onChatError() {
      let errorMessage = {
        content:
          "Something went wrong. If the issue persists, please contact support.",
        role: ChatMessageRole.COPILOT,
        type: ChatMessageType.ERROR,
      }

      // Either change the last message
      if (this.isCopilotTurn) {
        const lastMessage = this.messages[this.messages.length - 1]
        this.$set(lastMessage, {
          ...lastMessage,
          ...errorMessage,
        })
      } else {
        this.messages.push({ ...errorMessage, id: new Date().getTime() })
      }
      this.$analytics.saveEvent(AnalyticsEvent.CopilotError)
      this.isReceivingMessage = false
      this.isAwaitingMessage = false
      this.loadingMessage = ""
    },
    onLLMMessageChunk(msg: ChatMessage) {
      if (msg.type === ChatMessageType.PROGRESS) {
        this.isAwaitingMessage = true
        this.loadingMessage = msg.content

        return
      } else {
        this.isReceivingMessage = true
        this.isAwaitingMessage = false
        this.loadingMessage = ""
      }

      if (!this.isCopilotTurn) {
        this.activeIndex = 0
        this.messages.push({
          id: Date.now(),
          content: [],
          role: ChatMessageRole.COPILOT,
          type: ChatMessageType.TEXT,
        })
      }

      this.queuedChunk += msg.content
      this.renderQueuedChunk()

      if (msg.annotations) {
        this.updateAnnotations(msg.annotations)
      }
    },
    onLLMMessageComplete(remoteId: number) {
      this.renderQueuedChunk()
      this.remoteMessageIds[this.lastMessage.id as number] = remoteId
      this.isReceivingMessage = false
    },
    renderQueuedChunk() {
      if (
        this.messages[this.messages.length - 1]?.role === ChatMessageRole.USER
      ) {
        return
      }
      if (Array.isArray(this.messages[this.messages.length - 1]?.content)) {
        this.$set(this.messages[this.messages.length - 1], "content", [
          ...this.messages[this.messages.length - 1].content,
          this.queuedChunk,
        ])
      } else {
        this.$set(this.messages[this.messages.length - 1], "content", [
          this.messages[this.messages.length - 1].content,
          this.queuedChunk,
        ])
      }
      this.queuedChunk = ""
    },
    onPromptChanged(prompt: string) {
      this.userMessage = prompt
    },
    onSuggestionClicked(suggestion: ChatSuggestion) {
      this.userMessage = suggestion.text
      this.onUserMessage()
    },
    onCancelRequest() {
      this.socket.emit(CopilotSocketEvent.LLMRequestCancel)
    },
    onRequestCanceled() {
      this.isAwaitingMessage = false
      this.loadingMessage = ""
    },
    onMessageRegenerate(messageId: number) {
      const promptIndex = this.messages.findIndex((m) => m.id === messageId)
      const prompt = this.messages[promptIndex - 1]?.content
      this.$analytics.saveEvent(AnalyticsEvent.CopilotRegenerate)
      this.socket.emit(CopilotSocketEvent.UserMessage, prompt)
      this.isAwaitingMessage = true
      this.messages.push({
        id: Date.now(),
        content: prompt,
        role: ChatMessageRole.USER,
        timestamp: new Date().toISOString(),
      })
    },
    onChatClicked(e: Event) {
      const targetElement = e.target as HTMLDivElement

      if (targetElement?.dataset?.timestamp) {
        this.$emit(
          "timestamp-clicked",
          targetElement?.dataset?.timestamp?.replaceAll("$", "")
        )
      } else if (targetElement?.dataset?.videoTimestamp) {
        this.$emit(
          "video-timestamp-clicked",
          targetElement?.dataset?.videoTimestamp?.replaceAll(/-|\s/gm, "")
        )
      }
    },
    async onMessageFeedback(feedback: Partial<FeedbackPayload>) {
      try {
        await EvercamLabsApi.copilot.submitMessageFeedback({
          text: feedback.text!,
          type: feedback.type!,
          user: this.user.email,
          context: FeedbackContext.CopilotMessage,
          conversationId: this.conversationId,
          messageId: this.remoteMessageIds[feedback.messageId as number],
        })
      } catch (e) {
        console.error(e)
        this.$errorTracker.save(e)
      }
    },
    focusInput() {
      this.$setTimeout(() => {
        const input = this.$el.querySelector("textarea") as HTMLTextAreaElement
        input?.focus()
      }, 500)
    },
    getFormattedTimestamp(timestamp: string) {
      return (
        this.$moment
          // @ts-expect-error: moment should have tz function
          .tz(timestamp.replaceAll("$", ""), this.selectedProject.timezone)
          .format("dddd DD-MMM-YYYY h:mmA")
      )
    },
    getFormattedCameraName(cameraExid: CameraExid) {
      return this.camerasByExid[cameraExid.toLowerCase()]?.name
    },
    getFormattedProjectName(projectExid: ProjectExid) {
      return this.projectsByExid[projectExid.toLowerCase()]?.name
    },
    getCameraUrl(cameraExid: CameraExid) {
      return `${window.location.origin}/v2/cameras/${cameraExid.toLowerCase()}`
    },
    getProjectUrl(projectExid: ProjectExid) {
      return `${
        window.location.origin
      }/v2/projects/${projectExid.toLowerCase()}`
    },
    updateAnnotations(annotations: ChatMessageAnnotation[]) {
      this.annotations = {
        ...this.annotations,
        ...annotations.reduce(
          (acc, a) => ({
            ...acc,
            [a.text]: {
              url: a.url,
              index: a.index + 1,
            },
          }),
          {}
        ),
      }
    },
  },
})
